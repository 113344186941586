import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    showLoading:false,
    show: false,
    index: ''
  },
  getters: {
  },
  mutations: {
    setShowLoading(state,val){
      state.showLoading = val
    },
    setShow(state, val) {
      state.show = val
    },
    setIndex(state, val) {
      state.index = val
    }
  },
  actions: {
  },
  modules: {
  }
})
