<template>
  <div class="button">
    <div class="btn" @click="clickFn">
        <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
    name: 'Button',
    data() {
        return {
            // btnList: ['移动路径', '停止移动一', '停止移动二', '']
        }
    },
    methods: {
        clickFn() {
            this.$emit('addLandFn')
        }
    }
}
</script>

<style scoped lang="scss">
.button {
    display: flex;
    margin-right: 20px;
    .btn {
    z-index: 9999;
    color: #fff;
    width: 280px;
    // height: 80px;
    cursor: pointer;
    text-align: center;
    line-height: 80px;
    font-size: 30px;
    font-family: 'TanXianWeiZhengHeiJianTi-1', serif;
    font-weight: bold;
    background: url("~@/assets/img/button/blue_bt.png");
  }
}

</style>
