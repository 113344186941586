<template>
  <div class="popUp">
    <img src="../assets/Desktop/scenic_spot.png" alt="" class="jdImg">
    <img src="../assets/Desktop/close.png" alt="" class="clsImg" @click="handleClose">
    <div class="title">{{ contentData.facilityName }}</div>
   <div class="imgAndTextBox" v-if="show_type == 'tuwen'">
     <div class="imgContainer">
       <img :src="contentData.img[0]" class="img1">
       <img :src="contentData.img[1]">
     </div>
     <div class="text" v-html="contentData.content"></div>
<!--     <div class="text">-->
<!--       {{ contentData.content }}-->
<!--     </div>-->
   </div>


   <div class="videoBox" v-else-if="show_type == 'video'" >
     <video :src="contentData.video"  autoplay controls  width="100%" height="100%"></video>
   </div>

    <div class="videoBox"  v-else-if="show_type == 'audio'">
      <!-- <video :src="contentData.voiceUpload" autoplay controls loop width="100%" height="100%"></video> -->
      <video :src="contentData.voiceUpload" autoplay controls loop width="100%" height="100%"></video>
    </div>

   <div class="videoBox" v-else-if="show_type == '720'">
      <iframe width="100%" height="100%" :src="contentData.src720"></iframe>
   </div>
   
  </div>
</template>

<script>
export default {
  props: {
    contentData: {
      type: Object,
      default: () => {
        return {}
      }
    },
    show_type:{
      type:String,
      default:"tuwen"
    }

  },
  methods: {
    handleClose() {
      this.$emit('close')
    }
  },
};
</script>

<style scoped lang="scss">
::-webkit-scrollbar {
  width: 0px;
  height: 0px;
}
.popUp {
  z-index: 999;
  position: absolute;
  top: 4px;
  right: 10px;
  width: 1839px;
  height: 1017px;
  color: #fff;
  // padding: 0 30px;
  background: url("@/assets/Desktop/big_bgr2.png") 100% 100% no-repeat;
  background-size: contain;
  box-sizing: border-box;
  .jdImg {
    position: absolute;
    left: 25px;
    top: 38px;
  }

  .clsImg {
    position: absolute;
    right: 34px;
    top: 39px;
    cursor: pointer;
    text-shadow: 1px 1px 1px ;
  }

  .title {
    position: absolute;
    font-size: 53px;
    top: 27px;
    left: 87px;
    background-image: -webkit-linear-gradient(270deg, #ffffff, #46c9c8);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }

  .imgAndTextBox {
    width: 100%;
    height: 100%;
    // border: 1px solid red;

    .imgContainer {
      margin-top: 100px;
      width: 100%;
      height: 400px;
      padding: 20px 30px;
      box-sizing: border-box;

      img {
        width: 873px;
        height: 400px;
      }

      .img1 {
        margin-right: 30px;
      }
    }

    .text {
      max-height: 580px;
      overflow: scroll;
      padding: 0 30px;
      box-sizing: border-box;
      margin-top: 30px;
      text-indent: 2em;
      line-height: 1.7em;
      font-size: 35px;
      font-family: '微软雅黑';
      background-image: -webkit-linear-gradient(270deg, #88d9d9, #6dacac);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      font-weight: 700;
      // text-shadow: 0 0 5px #68d3d2, 0 0 5px #68d3d2, 0 0 5px #68d3d2;
      
      *{
        max-width: 1116px;
        font-size: 25px;
      }
    }
  }

  .videoBox {
    position: absolute;
    left: 16px;
    bottom: 24px;
    width: 98%;
    height: 88%;
    // border: 1px solid red;
    video{
      object-fit: fill;
      width: 100%;
      height: 100%;
    }

    .title {
      font-size: 25px;
      position: absolute;
      top: -149px;
      left: 46px;
    }
  }

}
</style>
