import axios from "axios";

const instance = axios.create({
    baseURL:'https://clmerchantapi.yueyat.net:8083'
});

instance.doGet = async (url)=>{
    let {data} = await instance.get(url);
    return data;
}

export default instance;
