<template>
  <div class="scene">
    <div id="container"></div>
    <div class="mark"></div>
    <!--   <div class="btnItem">-->
    <!--     <Button @addLandFn="click">移动路径</Button>-->
    <!--  </div>-->
    <PopUp
      :content-data="currentContent"
      :show_type="show_type"
      v-show="showPop"
      @close="handleClosePop"
    >
    </PopUp>
  </div>
</template>

<script>
import layerLegends from "@/data/layerLegends";
import pathConfig from "@/path/pathConfig";
import Button from "@/components/button";
import PopUp from "@/components/popUp";
import scenic from "@/api/scenic";

export default {
  name: "Scene",
  data() {
    return {
      dianwei: [],
      pathData: [],
      ws: null,
      coordZ: [],
      nowIndex: 1,
      isInPath: false,
      timerList: [],
      contentList: [],
      gouWuDian: [],
      weiShengJian: [],
      showPop: false,
      currentContent: {},
      show_type: "tuwen",
    };
  },
  components: {
    PopUp,
    Button,
  },
  methods: {
    async init() {
      this.$store.commit("setShowLoading", true);
      const server = window.configInfo.SCENE_CONFIG.SERVE;
      const id = window.configInfo.SCENE_CONFIG.ID;
      // 获取token
      const token = await this.$3d.getSceneToken(server, id);

      await this.$3d.destroy();

      const result = await this.$3d.loadSceneNew(
        token,
        server,
        id,
        document.getElementById("container")
      );
      await this.add3DTile();

      console.log("加载结果", result);
      this.$store.commit("setShowLoading", false);

      const sceneInfo = await this.$3d.getScenesInfo();
      console.log("sceneInfo", sceneInfo);

      await this.$3d.setupEventLister("sceneClick", (e) => {
        console.log(e.data[0].coord[0], e.data[0].coord[1], e.data[0].coordZ);
      });

      await this.$3d.setupEventLister("onLandmarkLayerClick", (e) => {
        console.log("onLandmarkClick", e);
        this.handlePointClick(e);
      });

      await this.initCamera();
      this.addPath();
      this.addLand();
      this.initWebSocket();
      await this.waitTime(10);
      await this.click();
    },
    // 等待time 秒
    waitTime(time) {
      return new Promise((resolve) => {
        let timer = setTimeout(() => {
          resolve();
        }, time * 1000);
        this.timerList.push(timer);
      });
    },
    // 处理精模
    async doJingMo(cameraInfo, cameraInfoOld) {
      // await this.rotateView();
      await this.makeCameraFly(cameraInfo, 5);
      await this.makeCameraFly(cameraInfoOld);
    },
    // 处理简模
    async doJianMo(cameraInfoOld) {
      // await this.rotateView();
      await this.makeCameraFly(cameraInfoOld);
    },
    //根据当前视角旋转
    rotateView() {
      return new Promise((resolve) => {
        this.$3d.rotateCamera(true, 15, "clockwise", true).then(() => {
          let timer = setTimeout(() => {
            resolve();
          }, 15000);
          this.timerList.push(timer);
        });
      });
    },
    // 使镜头飞过目标地点，在time秒时间内阻塞
    makeCameraFly(cameraInfo, time) {
      let timeout = time ? time * 1000 : cameraInfo.duration * 1000;
      return new Promise((resolve) => {
        this.$3d.setCamera(cameraInfo).then(() => {
          let timer = setTimeout(() => {
            resolve();
          }, timeout);
          this.timerList.push(timer);
        });
      });
    },
    // 镜头拉近并处理精模简模
    async cameraPush(index) {
      const elePoint = this.dianwei[index - 1];
      if(elePoint.isSkip) return
      // if (elePoint.isSkip) {
      //   console.log('木栈道不显示弹窗');
      //   this.showPop = false
      // }else {
      //   this.showPop = true;
      // }
      this.showPop = true;
      this.currentContent = this.contentList[index - 1];
      const cameraInfoOld = await this.$3d.getCameraInfo();
      console.log(cameraInfoOld);
      cameraInfoOld.fly = true;
      cameraInfoOld.duration = 3;
      let data = this.cameraData.find((item) => item.key == index);
      if (data.cameraInfo) {
        await this.doJingMo(data.cameraInfo, cameraInfoOld);
      }
      // if (elePoint.isJing) {
      //     let data = this.cameraData.find(item=>item.key == index);
      //   if (data.cameraInfo){
      //     await this.doJingMo(data.cameraInfo, cameraInfoOld);
      //   }
      // } else {
      //   await this.doJianMo(cameraInfoOld)
      // }
      this.showPop = false;
    },
    //添加地表图
    async addLand() {
      let jsonData = {
        id: "jingdian",
        name: "地标图层",
        coordType: 0,
        coordTypeZ: 0,
        autoScale: false,
        visible: true,
        legends: layerLegends,
        data: this.dianwei,
      };
      console.log("addLand", jsonData);
      await this.$3d.addLandmarkLayer(jsonData);
      await this.$3d.pickOverlay({
        overlayType: "landmarkLayer",
        idLayer: "jingdian",
        type: "click",
        isShowDecorator: false,
      });

      let jsonData1 = Object.assign({}, jsonData);
      jsonData1.id = "gouwudian";
      jsonData1.data = this.gouWuDian;
      jsonData1.visible = false;
      await this.$3d.addLandmarkLayer(jsonData1);
      await this.$3d.pickOverlay({
        overlayType: "landmarkLayer",
        idLayer: jsonData1.id,
        type: "click",
        isShowDecorator: false,
      });

      let jsonData2 = Object.assign({}, jsonData);
      jsonData2.id = "weishengjian";
      jsonData2.data = this.weiShengJian;
      jsonData2.visible = false;
      await this.$3d.addLandmarkLayer(jsonData2);
      await this.$3d.pickOverlay({
        overlayType: "landmarkLayer",
        idLayer: jsonData2.id,
        type: "click",
        isShowDecorator: false,
      });
    },
    //添加路径
    async addPath() {
      for (let pathDatum of this.pathData) {
        let data = Object.assign({}, pathConfig);
        data = { ...data, ...pathDatum };
        await this.$3d.addPath(data);
      }
    },

    // 一个个路径依次展示
    async setNowPathShow(index) {
      for (let i = 1; i <= this.pathData.length; i++) {
        if (i != index) {
          this.$3d.setOverlayVisibility("path" + i, "path", false);
        } else {
          await this.$3d.setOverlayVisibility("path" + i, "path", true);
        }
      }
    },
    // 开始第一次路线巡游
    async startFirst() {
      let cameraInfo = {
        coordType: 0,
        coordTypeZ: 0,
        distance: 843.82,
        pitch: 18.5,
        heading: 48.93,
        fov: 50,
        centerCoord: [109.7837372, 39.3701449],
        coordZ: -137.35,
        fly: true,
        duration: 1,
      };
      await this.makeCameraFly(cameraInfo);
      await this.setNowPathShow(this.nowIndex);
      await this.cameraFly(this.nowIndex);
    },
    // 处理线路
    async click() {
      console.log("1");
      this.nowIndex = 1;
      this.isInPath = !this.isInPath;
      if (this.isInPath) {
        await this.startFirst();
        while (this.isInPath) {
          await this.setNowPathShow(this.nowIndex);
          await this.cameraFly(this.nowIndex);
          await this.cameraPush(this.nowIndex);
          this.nowIndex++;
          if (this.nowIndex >= 15) {
            this.nowIndex = 1;
          }
        }
      } else {
        await this.initCamera();
        this.showPop = false;
        this.currentContent = {};
        //隐藏所有路径
        this.setNowPathShow(0);
        this.clearAllTimer();
      }
    },
    // 清除所有定时器
    clearAllTimer() {
      for (let timer of this.timerList) {
        clearInterval(timer);
        clearTimeout(timer);
      }
      this.timerList = [];
    },
    // 镜头飞行
    async cameraFly(index) {
      let cameraInfo = {
        coordType: 0,
        coordTypeZ: 0,
        distance: 120,
        pitch: 50,
        heading: 55.28,
        fov: 50,
        fly: true,
        duration: 5,
      };
      const point = this.dianwei[index - 1];
      cameraInfo.centerCoord = point.coord;
      cameraInfo.coordZ = point.coordZ;
      await this.makeCameraFly(cameraInfo);
    },
    async handlePointClick(e) {
      if (typeof e == "object") {
        const index = this.dianwei.findIndex((item) => item.id == e.idObj);
        const dianwei = this.dianwei[index];
        if (dianwei.isSkip) return;
        const camera = this.cameraData[index].cameraInfo;
        const content = this.contentList[index];
        console.log(index, camera, content);
        await this.$3d.setCamera(camera);
        this.currentContent = content;
        this.showPop = true;
      } else {
        const index = this.dianwei.findIndex((item) => item.id == e);
        const dianwei = this.dianwei[index];
        if (dianwei.isSkip) return;
        const camera = this.cameraData[index].cameraInfo;
        const content = this.contentList[index];
        await this.$3d.setCamera(camera);
        this.currentContent = content;
        this.showPop = true;
      }
    },
    // 初始化镜头
    async initCamera() {
      let cameraInfo = {
        coordType: 0,
        coordTypeZ: 0,
        centerCoord:  [109.7843375, 39.3692751],
        coordZ:  56.33,
        distance: 1073.1,
        pitch: 48.78,
        heading: 325.87,
        fov: 50,
        status: "free",
        fly: true,
        duration: 1,
      };
      await this.$3d.setCamera(cameraInfo);
    },
    /**
     * 处理websocket发送的消息
     * @param command 消息内容
     */
    handleMsgWs(command) {
      if (command == "link1") {
        // 线路1巡展
        this.click();
      } else if (command.includes("point")) {
        // 点位点击 todo 没有了
        // let id = command.replace('point-','');
        // this.handlePointClick(id);
      } else if (command == "link-stop") {
        this.initCamera();
        this.showPop = false;
        this.currentContent = {};
        this.nowIndex = 1;
        this.isInPath = !this.isInPath;
        //隐藏所有路径
        this.setNowPathShow(0);
        this.clearAllTimer();
      } else if (command.includes("type")) {
        let type = command.replace("type-", "");
        this.$3d.setOverlayVisibility(type, "landmarkLayer", true);
      } else if (command.includes("north")) {
        //let type = command.replace("type-",'')
        let arr = command.split("-");
        if (arr.length == 3) {
          let id = arr[1] + "-" + arr[2];
          let show_type = arr[0];
          this.show_type = show_type;
          this.handlePointClick(id);
        } else {
          console.log("error", "发送得的指令不正确");
        }
      }
    },
    // 初始化webSocket
    initWebSocket() {
      let id = Math.floor(Math.random() * 100000000) + 1
      this.ws = new WebSocket(window.configInfo.WEBSOCKET_URL + id);
      console.log(this.ws);
      this.ws.onopen = (e) => {
        console.log();
        console.log("ws init", e);
      };
      this.ws.onmessage = (e) => {
        console.log("websocket接收到信息:", e.data);
        this.handleMsgWs(e.data);
      };
    },
    // 销毁webSocket
    destroyWebSocket() {
      this.ws.close();
    },
    async initData() {
      const { data: Dianwei } = await axios.get("./data/dianwei.json");
      const { data: PathData } = await axios.get("./data/pathData.json");
      const { data: ContentData } = await axios.get("./data/content.json");
      const { data: CameraData } = await axios.get("./data/camera.json");
      const { data: GouwuDian } = await axios.get("./data/gouwudian.json");
      const { data: WeiShengJian } = await axios.get(
        "./data/weishengjian.json"
      );
      // this.contentList =
      const scenicData = await scenic.getScenicData(0);
      for (let index in scenicData) {
        ContentData[index] = { ...ContentData[index], ...scenicData[index] };
      }
      this.contentList = ContentData;
      console.log(ContentData);
      this.dianwei = Dianwei;
      // this.contentList = ContentData;
      this.pathData = PathData;
      this.cameraData = CameraData;
      this.gouWuDian = GouwuDian;
      this.weiShengJian = WeiShengJian;
    },
    handleClosePop() {
      this.showPop = false;
      this.currentContent = {};
      this.initCamera();
    },
    async add3DTile() {
      //   // http://localhost:6543/tileset.json
      //   let jsonData = {
      //     id: '3DTileLayer', // 图层对象 id，新建时调用者自己传入的唯一标识，用于各种操作的对象识别
      //     name: 'layerName', // 图层名称，支持为图层自定义名称
      //     url: 'http://localhost:6543/tileset.json', // 3dTile入口描述文件地址
      //     type: 'b3dm', // 3dTile类别，b3dm； i3dm；s3dm，目前仅支持 b3dm
      //     token: 'ABCDEFG', // 预留字段
      //     LOD: 16, // 倾斜摄影数据的细节加载系数。表示在当前视野距离下，加载倾斜摄影数据的级别系数。流渲染默认值是16，如果想更加清晰，可以设置8或者4，一定是2的倍数。端渲染默认是6，如果想更加清晰，可以设置5或者4，最小值为1。加载越清晰的数据会越耗费渲染侧的性能。
      //     centerTranslate: [400, 200, -1300], // 倾斜摄影数据中心点在x, y, z方向上的二次位移。数据默认值加偏移量是新位置，支持负数。（单位：米）
      //     centerRotation: [0, 0, 0], // 倾斜摄影数据中心点在x, y, z方向上的二次旋转。（单位：度）值域 -360~360
      //     visible: true, // 显隐控制，true：显示；false：隐藏
      //     alpha: 0.5, // 透明度，0：完全透明；1：完全不透明
      //   }; // 中心基准点调用 setBaseCenter 方法，默认都是东8时区
      //   const res = await this.$3d.add3DTile(jsonData);
      //   console.log('add3DTile',res);
    },
  },
  async mounted() {
    this.nowIndex = 1;
    this.clearAllTimer();
    await this.initData();
    await this.init();
  },
  beforeDestroy() {
    this.destroyWebSocket();
    this.clearAllTimer();
  },
};
</script>

<style lang="scss" scoped>
.scene {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;

  #container {
    width: 100%;
    height: 100%;
  }

  .ul {
    position: absolute;
    top: 160px;
    right: 990px;
    /* background-color: aqua; */
    z-index: 3;
  }

  /deep/ .btnItem {
    display: flex;
    margin-left: 800px;
    margin-top: -100px;

    Button {
      margin-left: 10px;
    }
  }
}
</style>
