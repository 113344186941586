import instance from "@/api/index";

export default {
    /**
     * 从服务器获取点位数据
     * @param lineIndex 线路索引-从0开始
     * @return {Promise<*[]|*>}
     */
    async getScenicData(lineIndex){
        const data = await instance.doGet('/screen/smart_guide/index');
        const lineList = data.data.lineList[lineIndex];
        if (lineList) return lineList['scenicList'];
        return [];
    }
}
