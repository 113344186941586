import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ThreeD from "@/service/3d";
import * as echarts from 'echarts';
import ElementUI from "element-ui";
import 'element-ui/lib/theme-chalk/index.css'
import axios from 'axios';

Vue.prototype.$3d = new ThreeD();
Vue.config.productionTip = false
Vue.prototype.$echarts = echarts;
Vue.prototype.$axios = axios;
window.axios = axios;
Vue.use(ElementUI)

let startApp = function () {
  axios.get('./config.json').then((res) => {
    window.configInfo = res.data;
    window.document.title = window.configInfo.HEAD_INFO.NAME;
    new Vue({
      router,
      store,
      render: (h) => h(App),
    }).$mount('#app');
  });
};
startApp();
