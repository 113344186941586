<template>
  <div id="header-customer">

  </div>
</template>

<script>
export default {
  name: "HeaderCustomer"
}
</script>

<style scoped>
#header-customer{
  width: 3744px;
  height: 188px;
  background: url("@/assets/img/title.png") 100% 100%;
  background-size: contain;
  position: absolute;
  left: 0;
  top: 0;
  z-index: 1000;
}
</style>
