export default [
    {
        name: "停车场",
        color: "#73FFFF",
        iconName: "tingchechang",
        iconScale: 3,
        labelScale: 1,
    },
    {
        name: "警务室",
        color: "#73FFFF",
        iconName: "jingwushi",
        iconScale: 3,
        labelScale: 1,
    },
    {
        name: "游客中心",
        color: "#73FFFF",
        iconName: "youkezhongxin",
        iconScale: 3,
        labelScale: 1,
    },
    {
        name: "门牌楼",
        color: "#73FFFF",
        iconName: "menpailou",
        iconScale: 3,
        labelScale: 1,
    },
    {
        name: "铜像广场",
        color: "#73FFFF",
        iconName: "tongxiangguangchang",
        iconScale: 3,
        labelScale: 1,
    },
    {
        name: "九十九级吉祥台阶",
        color: "#73FFFF",
        iconName: "jixiangtaijie",
        iconScale: 3,
        labelScale: 1,
    },
    {
        name: "阿拉坦甘德尔敖包",
        color: "#73FFFF",
        iconName: "alatangande",
        iconScale: 3,
        labelScale: 1,
    },
    {
        name: "苏勒德祭坛",
        color: "#73FFFF",
        iconName: "laoledejitan",
        iconScale: 3,
        labelScale: 1,
    },
    {
        name: "成吉思汗陵宫",
        color: "#73FFFF",
        iconName: "chengjisihan",
        iconScale: 3,
        labelScale: 1,
    },
    {
        name: "长明圣灯大殿",
        color: "#73FFFF",
        iconName: "changmingshengdeng",
        iconScale: 3,
        labelScale: 1,
    },
    {
        name: "成吉思汗展馆",
        color: "#73FFFF",
        iconName: "lishiwenhuazhanlanguan",
        iconScale: 3,
        labelScale: 1,
    },
    {
        name: "额希哈屯祭祀殿",
        color: "#73FFFF",
        iconName: "exihatong",
        iconScale: 3,
        labelScale: 1,
    },
    {
        name: "木栈道",
        color: "#73FFFF",
        iconName: "muzhandao",
        iconScale: 3,
        labelScale: 1,
    },
    {
        name: "游客休闲区",
        color: "#73FFFF",
        iconName: "youkexiuxiqu",
        iconScale: 3,
        labelScale: 1,
    },
    {
        name: "卫生间",
        color: "#73FFFF",
        iconName: "weishengjian-point",
        iconScale: 3,
        labelScale: 1,
    },
    {
        name: "购物点",
        color: "#73FFFF",
        iconName: "gouwudian-point",
        iconScale: 3,
        labelScale: 1,
    },
]
