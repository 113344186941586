<template>
  <div id="app"
       :style="{ transform: 'translate(-50%,-50%) scale(' + scale + ')','--pageWidth':pageInfo.width+'px','--pageHeight':pageInfo.height+'px'}">
    <!-- <HeaderCustomer></HeaderCustomer> -->
    <Scene ref='scene'></Scene>
    <router-view/>
    <div class="loading" v-show="showLoading">
      <img src="/img/loading.gif" alt/>
    </div>
    <div v-for="(item, index) in contentList" :key='index'>
      <PopUp v-if="(index+1) === $store.state.index">
        <template #title><h2>{{ item.title }}</h2></template>
        <template #content><p>{{ item.content }}</p></template>
      </PopUp>
    </div>
  </div>
</template>

<script>
import Scene from "@/components/scene/Scene";
import Button from '@/components/button'
import PopUp from '@/components/popUp'
import {mapState} from "vuex";
import HeaderCustomer from "@/components/HeaderCustomer";
// import PopUp from './components/popUp.vue';
export default {
  components: {HeaderCustomer, Scene, Button, PopUp},
  data() {
    return {
      scale: 1,
      contentList: [],
      pageInfo: {}
    };
  },
  computed: {
    ...mapState(['showLoading'])
  },
  methods: {
    setScreen() {
      // 可视区域的尺寸
      let visibleWidth = window.innerWidth;
      let visibleHeight = window.innerHeight;
      this.pageInfo.width = window.configInfo.PAGE_INFO.WIDTH;
      this.pageInfo.height = window.configInfo.PAGE_INFO.HEIGHT;
      // 手动配置的面板尺寸
      let manualWidth = this.pageInfo.width;
      let manualHeight = this.pageInfo.height;
      // 计算横向和纵向的缩放系数
      var widthPercentage = (1.0 * visibleWidth) / manualWidth;
      var heightPercentage = (1.0 * visibleHeight) / manualHeight;
      // 取得最小缩放系数
      this.scale = Math.min(widthPercentage, heightPercentage);
    },
    // addLandmaker() {
    //   this.$refs.scene.setCameraFn()
    // }
  },
  async mounted() {
    this.setScreen()
    window.addEventListener('resize', this.setScreen.bind(this))
    const {data: ContentList} = await axios.get("./data/content.json")

    this.contentList = ContentList
    console.log(this.contentList);
  }
}
</script>

<style lang="scss">
body {
  background-color: #1e3f51;
}

#app {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
  width: var(--pageWidth);
  height: var(--pageHeight);
  position: absolute;
  left: 50%;
  top: 50%;

  .loading {
    position: absolute;
    top: 0;
    left: 0;
    width: 3744px;
    height: 1040px;
    z-index: 9999;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.3);
    pointer-events: all;
  }

  p {
    text-indent: 2em;
    letter-spacing: 2px;
    font-size: 24px;
  }
}
</style>
