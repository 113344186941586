/**
 * 图观api封装定义
 * @author haowei
 */
export default class ThreeD {
    appInstance;

    constructor() {
        this.appInstance = new TGApp.App();
        window.appInstance = this.appInstance;
        window.$3d = this;
        console.log('3d依赖初始化完毕!')
    }

    static EVENT_TYPE = {
        landmarkLayerClick: '3d-event-onLandmarkLayerClick',
        sceneChanged: '3d-event-onSceneChanged',
    }

    loadScene(url, container) {
        return new Promise((resolve, reject) => {
            try {
                this.appInstance.initScene(
                    {
                        container,
                        mode: "scene2",
                        token: "",
                        url: url,
                    }, (result) => {

                        resolve(result);
                    });
            } catch (e) {
                console.error('调用TGApp实例加载场景 失败');
                reject(e);
            }
        });
    }

    /**
     * 获取端渲染token
     * @param server 服务器地址，例如：https://www.tuguan.net
     * @param id 场景id
     * @return {Promise<unknown>}
     */
    getSceneToken(server, id) {
        return new Promise((resolve, reject) => {
            // 定义URL模版
            const tokenUrl = `${server}/api/user/v1/visitorScene/${id}`

            let timer = null;

            let xhr = new XMLHttpRequest();
            // 设置请求地址和参数
            xhr.open("post", tokenUrl, true);
            // 设置请求头
            xhr.setRequestHeader("Content-Type", "application/json charset=utf-8");
            // 监听readystate状态
            xhr.onreadystatechange = () => {
                if (xhr.readyState == 4 && xhr.status == 200) {
                    let result = JSON.parse(xhr.responseText);
                    clearInterval(timer);
                    // 请求成功
                    resolve(result.accessToken);
                }
            };
            let time = 0
            timer = setInterval(() => {
                time++;
            }, 1000)
            if (time >= 30) {
                reject('请求超时')
            }
            // 发送请求
            xhr.send();
        });
    }

    /**
     * 新---加载端渲染场景
     * @param token 端渲染token
     * @param server 服务器地址
     * @param id 场景id
     * @param dom 容器dom元素
     */
    loadSceneNew(token, server, id, dom) {
        const sceneUrl = `${server}/publish/scene/api/${id}`
        return new Promise(((resolve, reject) => {
            this.appInstance.initService({
                container: dom,
                mode: 'scene',
                token,
                url: sceneUrl,
                resourceBasePath: './scene'
            }, (result) => {
                if (result.result != 1) {
                    reject(result);
                }
                resolve(result);
                // this.setupEventLister('onServiceInit', (e) => {
                //     console.log('onServiceInit')
                //     resolve(true);
                // }).catch(err => {
                //     reject(err);
                // })
            });
        }))
    }

    /**
     * 初始化流渲染
     * @param url 流渲染地址
     * @param token token
     * @param dom 元素
     * @returns {Promise<unknown>}
     */
    initStream(url, token, dom) {
        return new Promise(((resolve, reject) => {
            this.appInstance.initService({
                container: dom,
                mode: 'scene',
                token,
                url
            }, (result) => {
                if (result.result != 1) {
                    reject(result);
                }
                this.setupEventLister('onServiceInit', (e) => {
                    resolve(true);
                }).catch(err => {
                    reject(err);
                })
            });
        }))
    }

    /**
     * 获取场景信息
     * @returns {Promise<unknown>}
     */
    getScenesInfo() {
        return new Promise((resolve) => {
            this.appInstance.uniCall('getScenesInfo', {}, (result) => {
                resolve(result);
            });
        });
    }

    /**
     * 获取状态信息
     * @param {*} type
     * @returns
     */
    getStates() {
        return new Promise((resolve) => {
            this.appInstance.uniCall('getStates', {}, (result) => {
                resolve(result)
            })
        })
    }

    /**
     * 切换状态
     */
    switchState({name, sceneName}) {
        return new Promise((resolve) => {
            this.appInstance.uniCall('switchState', {name, sceneName}, (result) => {
                resolve(result)
            })
        })
    }

    setOverlayVisibility(id, overlayType, visible) {
        return new Promise((resolve) => {
            this.appInstance.uniCall('setOverlayVisibility', {id, overlayType, visible}, (result) => {
                console.log('setOverlayVisibility', id, result)
                resolve(result)
            })
        })
    }

    // 根据类型获取全部模型
    getModelsByType(type) {
        return new Promise((resolve) => {
            this.appInstance.uniCall('getModelsByType', {modelType: type}, (result) => {
                console.log(result);
                resolve(result);
            });
        });
    }

    /**
     * 切换场景
     * @param name 场景名称
     * @param duration 持续时间
     * @returns {Promise<unknown>}
     */
    switchScene(name, duration) {
        return new Promise((resolve) => {
            this.appInstance.uniCall('switchScene', {name, duration}, (result) => {
                resolve(result);
            });
        });
    }

    /**
     * 获取场景相机信息  镜头视界
     * @returns {Promise<unknown>}
     */
    getCameraInfo() {
        return new Promise((resolve) => {
            this.appInstance.uniCall('getCameraInfo', {}, (result) => {
                resolve(result);
            });
        });
    }

    /**
     * 设置摄像头
     * @param {Object} data 参数
     * @param data.coordType XY 轴坐标模式 坐标类型(0:X 经度 Y 纬度；1:X 米 Y 米，相对世界 00 点) 目前该属性仅支持属性值为 0
     * @param data.coordTypeZ Z 轴坐标模式 坐标高度类型，单位：米(0:相对 3D 世界 0 海拔；1:相对 3D 世界地表；2:相对 3D 模型表面) 目前该属性仅支持属性值为 0
     * @param data.centerCoord 中心点的坐标 lng,lat
     * @param data.coordZ Z 轴高度（单位：米）
     * @param data.distance 镜头距中心点距离(单位:米)
     * @param data.pitch 镜头俯仰角(5~89)
     * @param data.heading 镜头偏航角(0 正北, 0~359)
     * @param data.fly true: 飞行动画(飞行时间根据当前点与目标点计算,则 pitch 及 heading 不生效, 会自行计算);false: 立刻跳转过去(有一个短暂飞行动画,并按照 distance, pitch, heading 设置镜头)
     * @param data.duration 飞行时间，秒
     * @returns {Promise<unknown>}
     */
    setCamera({coordType, coordTypeZ, centerCoord, coordZ, distance, pitch, heading, fly, duration}) {
        return new Promise((resolve) => {
            this.appInstance.uniCall('setCamera', {
                coordType,
                coordTypeZ,
                centerCoord,
                coordZ,
                distance,
                pitch,
                heading,
                fly,
                duration
            }, (result) => {
                console.log('setCamera', result);
                resolve(result);
            });
        });
    }

    /**
     * 聚焦模型
     * @param id 模型id
     * @param modelType 模型类型
     * @param distance 距离模型距离
     * @returns {Promise<unknown>}
     */
    focusModel(id, modelType, distance) {
        return new Promise((resolve) => {
            this.appInstance.uniCall('focusModel', {id, modelType, distance}, (result) => {
                resolve(result);
            });
        });
    }

    /**
     * 开始单选图层
     *
     * @param {Object} data 参数
     * @param data.overlayType 图层对象类别
     * @param data.idLayer 如果具体想限定图层对象，则填写图层对象 id，如不限制，可不填
     * @param data.type 单选模式，click：点击选中；hover：悬停选中
     * @param data.allowMultiple 是否支持多选，false：单选，true：多选
     * @param data.isShowDecorator 表示是否显示选中的高亮装饰符，true：显示，false：不显示，默认：true
     * @return {Promise<unknown>}
     */
    pickOverlay({overlayType, idLayer, type, allowMultiple, isShowDecorator}) {
        return new Promise((resolve, reject) => {
            this.appInstance.uniCall('pickOverlay', {
                overlayType,
                idLayer,
                type,
                allowMultiple,
                isShowDecorator
            }, (result) => {
                console.log(result);
                if (result.result != 1) {
                    console.error(result, 'pickOverlayError')
                    reject(result);
                } else {
                    resolve(true);
                }
            });
        });
    }

    /**
     * 开始单选择模型
     * @param {Object} data 参数
     * @param data.modelType -- model（模型），building（建筑物）
     * @param data.type 单选模式，click：点击选中；hover：悬停选中
     */
    pickModel({modelType, type, isShowDecorator}) {
        return new Promise((resolve, reject) => {
            this.appInstance.uniCall('pickModel', {modelType, type, isShowDecorator}, (result) => {
                if (result.result != 1) {
                    console.error(result, 'pickModelError')
                    reject(result);
                } else {
                    resolve(true);
                }
            });
        });
    }

    /**
     * 设置模型变换2
     * @param {Object} data 参数
     * @param data.id 模型对象 id，新建时调用者自己传入的唯一标识，用于各种操作的对象识别
     * @param data.coordType xy 轴坐标模式 坐标类型(0:x 经度 y 纬度；1:x 米 y 米，相对世界 00 点) 目前该属性仅支持属性值为 0
     * @param data.coordTypeZ z 轴坐标模式 坐标高度类型，单位：米(0:相对 3D 世界 0 海拔；1:相对 3D 世界地表；2:相对 3D 模型表面) 目前该属性仅支持属性值为 0
     * @param data.coordX X 轴坐标 // 如果 coordType: 0，表示经度，如果 coordType: 1，表示 X 米，相对世界 0，0 点 // 如果值为 null，或者没有传递这个属性，则表示不发生改变
     * @param data.coordY Y 轴坐标 // 如果 coordType: 0，表示纬度，如果 coordType: 1，表示 Y 米，相对世界 0，0 点 // 如果值为 null，或者没有传递这个属性，则表示不发生改变
     * @param data.coordZ Z 轴高度（单位：米） // 如果值为 null，或者没有传递这个属性，则表示不发生改变
     * @param data.rotationX X 轴旋转度数（单位：度） // 0 正北，值域 -360~360 // 如果值为 null，或者没有传递这个属性，则表示不发生改变
     * @param data.rotationY Y 轴旋转度数（单位：度） // 0 水平，值域 -360~360 // 如果值为 null，或者没有传递这个属性，则表示不发生改变
     * @param data.rotationZ Z 轴旋转度数（单位：度） // 0 水平，值域 -360~360 // 如果值为 null，或者没有传递这个属性，则表示不发生改变
     * @param data.scaleX    X 轴放缩倍数（单位：倍） // 1 为原始大小，值域 大于 0，如果小于 1 表示缩小，大于 1 表示放大 // 如果值为 null，或者没有传递这个属性，则表示不发生改变
     * @param data.scaleY Y 轴放缩倍数（单位：倍） // 1 为原始大小，值域 大于 0，如果小于 1 表示缩小，大于 1 表示放大 // 如果值为 null，或者没有传递这个属性，则表示不发生改变
     * @param data.scaleZ    Z 轴放缩倍数（单位：倍） // 1 为原始大小，值域 大于 0，如果小于 1 表示缩小，大于 1 表示放大 // 如果值为 null，或者没有传递这个属性，则表示不发生改变
     * @param data.duration 持续时长（单位：秒），表示花费多少时间长度，变化到目标值，值域范围大于 0，可以是小数
     * @returns {Promise<unknown>}
     */
    setModelTransform2({
                           id,
                           coordType,
                           coordTypeZ,
                           coordX,
                           coordY,
                           coordZ,
                           rotationX,
                           rotationY,
                           rotationZ,
                           scaleX,
                           scaleY,
                           scaleZ,
                           duration
                       }) {
        return new Promise((resolve, reject) => {
            this.appInstance.uniCall('setModelTransform2', {
                id,
                coordType,
                coordTypeZ,
                coordX,
                coordY,
                coordZ,
                rotationX,
                rotationY,
                rotationZ,
                scaleX,
                scaleY,
                scaleZ,
                duration
            }, (result) => {
                if (result.result != 1) {
                    console.error(result, 'setModelTransform2Error')
                    reject(result);
                } else {
                    resolve(true);
                }
            });
        });
    }

    /**
     * 持续闪烁模型
     * @param id 模型对象 id，新建时调用者自己传入的唯一标识，用于各种操作的对象识别
     * @param duration 闪烁周期（单位：秒）
     * @param color 闪烁颜色（HEX 颜色值）
     * @param type 闪烁风格类别，详见下表：闪烁风格类别，default：默认风格
     * @returns {Promise<unknown>}
     */
    blinkingModel(id, duration, color, type) {
        return new Promise(resolve => {
            this.appInstance.uniCall('blinkingModel', {id, duration, color, type}, (result) => {
                resolve(result);
            });
        });
    }

    /**
     * 设置模型闪烁状态
     * @param id 模型对象 id，新建时调用者自己传入的唯一标识，用于各种操作的对象识别
     * @param state 模型持续闪烁状态，pause：暂停；continue：继续；stop：停止，回到初始状态
     * @returns {Promise<unknown>}
     */
    setModelBlinkState(id, state) {
        return new Promise(resolve => {
            this.appInstance.uniCall('setModelBlinkState', {id, state}, (result) => {
                resolve(result);
            });
        });
    }

    /**
     * 设置模型样式
     * @param {Object} data 参数
     * @param data.id 模型 ID
     * @param data.alpha 模型透明度
     * @param data.scale 半径(单位:米)
     * @param data.maskType color、picture、none
     * @param data.maskAlpha 遮罩透明度
     * @param data.maskColor 遮罩颜色（HEX 颜色值）
     * @param data.maskPicture 图片枚举，内置，或指定资源 URL
     * @param data.maskPictureScale 图片贴图的比例
     * @param data.maskFlowSpeed 遮罩贴图的流动速度系数
     * @param data.maskFlowDirection 遮罩贴图 UV 流动方向角度， 0-360 度
     * @param data.includeChildren 当前样式设置是否包含子节点，此功能可以实现例如控制子节点显隐的功能
     * @param data.xRay X 光样式（半透明效果，且不会被前方物体遮挡）开关，on：开启 X 光效果；off：关闭 X 光效果
     */
    setModelStyle({
                      id,
                      alpha,
                      scale,
                      maskType,
                      maskAlpha,
                      maskColor,
                      maskPicture,
                      maskPictureScale,
                      maskFlowSpeed,
                      maskFlowDirection,
                      includeChildren,
                      xRay
                  }) {
        return new Promise(resolve => {
            this.appInstance.uniCall('setModelStyle', {
                id,
                alpha,
                scale,
                maskType,
                maskAlpha,
                maskColor,
                maskPicture,
                maskPictureScale,
                maskFlowSpeed,
                maskFlowDirection,
                includeChildren,
                xRay
            }, (result) => {
                resolve(result);
            });
        });
    }

    /**
     * 添加模型 Tip
     * @param {Object} data 参数
     * @param data.id 模型对象 id，新建时调用者自己传入的唯一标识，用于各种操作的对象识别
     * @param data.url Tip 窗口页面地址
     * @param data.divId HTML 侧弹出标牌的 dom div id，如果这个不为""，url 属性不起作用
     * @param data.size Tip 窗口宽高 xy，x：窗口宽；y：窗口高（单位：像素）
     * @param data.offset Tip 左上角相对 对象中心点 偏移量 xy，x：为正，向右偏移；y：为正，向上偏移（单位：像素）
     * @param data.isShowClose 是否显示右上角关闭按钮，默认显示关闭按钮
     * @returns {Promise<unknown>}
     */
    addModelTip({id, url, divId, size, offset, isShowClose}) {
        return new Promise(resolve => {
            this.appInstance.uniCall('addModelTip', {id, url, divId, size, offset, isShowClose}, (result) => {
                // console.log(result);
                resolve(result);
            });
        });
    }

    /**
     * 移除模型tip
     * @param id 模型id
     * @returns {Promise<unknown>}
     */
    removeModelTip(id) {
        return new Promise(resolve => {
            this.appInstance.uniCall('removeModelTip', {id}, (result) => {
                resolve(result);
            });
        });
    }

    /**
     * 添加圆形区域效果
     * @param {Object} data 参数
     * @param data.id 图层对象 id，新建时调用者自己传入的唯一标识，用于各种操作的对象识别
     * @param data.name 图层名称，支持为图层自定义名称
     * @param data.coordType XY 轴坐标类别，0：X 经度，Y 纬度；1：X 米，Y 米，相对世界 00 点 目前该属性仅支持属性值为 0
     * @param data.coordTypeZ Z 轴坐标类别，0：相对 3D 世界 0 海拔；1：相对 3D 世界地表；2：相对 3D 模型表面（单位：米） 目前该属性仅支持属性值为 0
     * @param data.coordZ 高度(单位:米)
     * @param data.type 区域边界样式类别 （具体类型参考下图 type 枚举值，端渲染暂不支持 Gradient01）
     * @param data.color 轮廓颜色(HEX 颜色值)
     * @param data.areaHeight 围栏高度(单位:米)
     * @param data.fillArea 区域填充样式类别 （具体类型参考下图 fillArea 枚举值）
     * @param data.fillPosition 区域填充的位置，top/bottom，默认 top
     * @param data.tag 用户自定标签，用户保存用户的扩展数据
     * @param data.center 圆心 XY 轴坐标，X：经度；Y：纬度
     * @param data.radius 半径(单位:米)
     * @returns {Promise<unknown>}
     */
    addCircularArea({
                        id,
                        name,
                        coordType,
                        coordTypeZ,
                        coordZ,
                        type,
                        color,
                        areaHeight,
                        fillArea,
                        fillPosition,
                        tag,
                        center,
                        radius
                    }) {
        return new Promise(resolve => {
            this.appInstance.uniCall('addCircularArea', {
                id,
                name,
                coordType,
                coordTypeZ,
                coordZ,
                type,
                color,
                areaHeight,
                fillArea,
                fillPosition,
                tag,
                center,
                radius
            }, (result) => {
                resolve(result);
            });
        });
    }


    /**
     * 添加轨迹图层
     * @param {Object} obj 参数
     * @param obj.id 图层对象 id，新建时调用者自己传入的唯一标识，用于各种操作的对象识别
     * @param obj.name 图层名称，支持为图层自定义名称
     * @param obj.coordType XY 轴坐标类别，0：X 经度，Y 纬度；1：X 米，Y 米，相对世界 00 点 目前该属性仅支持属性值为 0
     * @param obj.coordTypeZ XY 轴坐标类别，0：X 经度，Y 纬度；1：X 米，Y 米，相对世界 00 点 目前该属性仅支持属性值为 0
     * @param obj.trackStyle 尾迹内置风格
     * @param obj.trackDuration 尾迹粒子生命周期
     * @param obj.objLife 批号消批时间长度
     * @param obj.trackWidth 尾迹粒子的宽度
     * @param obj.duration 从上一位置移动到新位置花费的时间长度（单位，秒）这里的值是更新数据可以使用的默认值度
     * @param obj.visible 添加当前图层时默认显示还是隐藏度
     * @param obj.legends 定义图层包含图例，支持为不同图例定义各自样式
     * @param obj.data 定义图层可视化数据
     * @return {Promise<unknown>}
     */
    addTrailLayer({
                      id,
                      name,
                      coordType,
                      coordTypeZ,
                      trackStyle,
                      trackDuration,
                      objLife,
                      trackWidth,
                      duration,
                      visible,
                      legends,
                      data
                  }) {
        return new Promise(resolve => {
            this.appInstance.uniCall('addTrailLayer', {
                id,
                name,
                coordType,
                coordTypeZ,
                trackStyle,
                trackDuration,
                objLife,
                trackWidth,
                duration,
                visible,
                legends,
                data
            }, (result) => {
                resolve(result);
            });
        })
    }

    updateTrailLayerCoord(data) {
        return new Promise(resolve => {
            this.appInstance.uniCall('updateTrailLayerCoord', data, (result) => {
                // console.log(result);
                resolve(result);
            });
        })
    }

    /**
     * 旋转摄像头
     * @param enabled    是否启用相机围绕目标飞行
     * @param duration 飞行一周所需要的秒数，数值越大飞行越慢
     * @param direction 飞行方向，clockwise 为顺时针，counterclockwise 为逆时针
     * @param interruptable 是否可以被打断，默认为 true
     * @returns {Promise<unknown>}
     */
    rotateCamera(enabled, duration, direction, interruptable) {
        return new Promise(resolve => {
            this.appInstance.uniCall('rotateCamera', {enabled, duration, direction, interruptable}, (result) => {
                console.log(result);
                resolve(result);
            });
        });
    }

    /**
     * 高亮建筑
     * @param id 模型对象 id，新建时调用者自己传入的唯一标识，用于各种操作的对象识别
     * @param type 高亮风格，style1：默认风格 none:取消高亮
     * @returns {Promise<unknown>}
     */
    highlightBuilding(id, type) {
        return new Promise(resolve => {
            this.appInstance.uniCall('highlightBuilding', {id, type}, (result) => {
                resolve(result);
            });
        });
    }

    /**
     * 展开建筑楼层
     * @param id 模型对象 id，新建时调用者自己传入的唯一标识，用于各种操作的对象识别
     * @param floorPadding 每个楼层的间距（单位：米）
     * @param duration 展开动画的时间长度（单位：秒）
     * @returns {Promise<unknown>}
     */
    expandBuildingFloor(id, floorPadding, duration) {
        return new Promise(resolve => {
            this.appInstance.uniCall('expandBuildingFloor', {id, floorPadding, duration}, (result) => {
                resolve(result);
            });
        });
    }

    /**
     * 恢复建筑楼层
     * @param id 模型 ID
     * @param animation 动画类型 (1:伸缩杆动画)
     * @returns {Promise<unknown>}
     */
    resetBuildingFloor(id, animation) {
        return new Promise(resolve => {
            this.appInstance.uniCall('resetBuildingFloor', {id, animation}, (result) => {
                resolve(result);
            });
        });
    }

    /**
     * 高亮楼层
     * @param id 模型对象 id，新建时调用者自己传入的唯一标识，用于各种操作的对象识别
     * @param floor 要拆解的楼层号，1 楼为 1，地下一楼为 -1，依此类推
     * @param type 高亮风格，style1：默认风格 none:取消高亮
     * @returns {Promise<unknown>}
     */
    highlightFloor(id, floor, type) {
        return new Promise(resolve => {
            this.appInstance.uniCall('highlightFloor', {id, floor, type}, (result) => {
                resolve(result);
            });
        });
    }

    /**
     * 设置环境天气
     * @param envWeather 天气
     * @returns {Promise<unknown>}
     */
    setEnvWeather(envWeather) {
        return new Promise(resolve => {
            this.appInstance.uniCall('setEnvWeather', {envWeather}, (result) => {
                resolve(result);
            });
        });
    }

    /**
     * 设置环境光照
     * @param envTime 设置日期以配合纬度获取正确的日照角度
     * @param alwayForward    时间是否总是向前，false：如果新时间小于当前时间，时间会倒回去，true：如果新时间小于当前时间，会经历23:59分再到新时间
     * @param duration 切换时间，秒
     * @returns {Promise<unknown>}
     */
    setEnvTime(envTime, alwayForward, duration) {
        return new Promise(resolve => {
            this.appInstance.uniCall('setEnvTime', {envTime, alwayForward, duration}, (result) => {
                console.log(result);
                resolve(result);
            });
        });
    }


    /**
     * 添加类型区域图
     * @param data {Object} 参数
     * @returns {Promise<unknown>}
     */
    addTypeAreaLayer(data) {
        // this.appInstance.uniCall('addTypeAreaLayer', data, (result) => {
        //     console.log(result,'addTypeAreaLayer-result');
        // });
        return new Promise(resolve => {
            this.appInstance.uniCall('addTypeAreaLayer', data, (result) => {
                console.log(result, 'addTypeAreaLayer-result');
                resolve(result);
            });
        });
    }

    /**
     * 设置模型显隐
     * @param ids 模型对象 id 集合
     * @param visible 模型需要设置显隐的状态，true: 显示，false：隐藏
     * @returns {Promise<unknown>}
     */
    setModelVisibility(ids, visible) {
        return new Promise(resolve => {
            this.appInstance.uniCall('setModelVisibility', {ids, visible}, (result) => {
                resolve(result);
            });
        });
    }

    /**
     * 拆解建筑楼层
     * @param id 模型 ID
     * @param floor 要拆解的楼层(1 楼为 1,地下一楼为-1)
     * @param animation 动画类型 (1:伸缩杆动画)
     * @returns {Promise<unknown>}
     */
    showBuildingFloor(id, floor, animation) {
        return new Promise(resolve => {
            this.appInstance.uniCall('showBuildingFloor', {id, floor, animation}, (result) => {
                console.log(result);
                resolve(result);
            });
        });
    }

    /**
     * 聚焦楼层
     * @param buildingId 建筑物的id
     * @param floor 楼层号
     * @param distance 镜头距楼层的距离(单位:米)
     * @returns {Promise<unknown>}
     */
    focusFloor(buildingId, floor, distance) {
        return new Promise(resolve => {
            this.appInstance.uniCall('focusFloor', {buildingId, floor, distance}, (result) => {
                resolve(result);
            });
        });
    }

    /**
     * 删除类别图层
     * @param overlayType 覆盖物类型,支持 all ，参考上面图层对象类别枚举表格
     * @returns {Promise<unknown>}
     */
    clearOverlayType(overlayType) {
        return new Promise(resolve => {
            this.appInstance.uniCall('clearOverlayType', {overlayType}, (result) => {
                console.log(overlayType, result);
                resolve(result);
            });
        });
    }

    /**
     * 添加图层对象Tip
     * @param {Object} data 参数
     * @param data.id 图层对象 id，新建时调用者自己传入的唯一标识，用于各种操作的对象识别
     * @param data.url Tip 窗口页面地址
     * @param data.divId HTML 侧弹出标牌的 dom div id，如果这个不为""，url 属性不起作用
     * @param data.isShowClose 是否显示右上角关闭按钮，默认显示关闭按钮
     * @param data.size Tip 窗口宽高 xy，x：窗口宽；y：窗口高（单位：像素）
     * @param data.offset Tip 左上角相对 对象中心点 偏移量 xy，x：为正，向右偏移；y：为正，向上偏移（单位：像素）
     * @param data.overlayType 图层对象类别，参考上面图层对象类别枚举表格
     * @returns {Promise<unknown>}
     */
    addOverlayTip({id, url, divId, isShowClose, size, offset, overlayType}) {
        return new Promise(resolve => {
            this.appInstance.uniCall('addOverlayTip', {
                id,
                url,
                divId,
                isShowClose,
                size,
                offset,
                overlayType
            }, (result) => {
                console.log(result);
                resolve(result);
            });
        });
    }

    /**
     * 移除图层对象Tip
     * @param id 图层对象 id，新建时调用者自己传入的唯一标识，用于各种操作的对象识别
     * @param overlayType 图层对象类别，参考上面图层对象类别枚举表格
     * @returns {Promise<unknown>}
     */
    removeOverlayTip(id, overlayType) {
        return new Promise(resolve => {
            this.appInstance.uniCall('removeOverlayTip', {id, overlayType}, (result) => {
                console.log(result)
                resolve(result);
            });
        });
    }

    addLandmarkLayer(data) {
        return new Promise(resolve => {
            this.appInstance.uniCall('addLandmarkLayer', data, (result) => {
                console.log(result);
                resolve(result);
            });
        });
    }

    /**
     * 删除图层
     * @param id 图层对象 id，新建时调用者自己传入的唯一标识，用于各种操作的对象识别
     * @param overlayType 图层对象类别
     * @returns {Promise<unknown>}
     */
    removeOverlay(id, overlayType) {
        return new Promise(resolve => {
            this.appInstance.uniCall('removeOverlay', {id, overlayType}, (result) => {
                console.log(result);
                resolve(result);
            });
        });
    }

    /**
     *
     * @param id    模型特效 ID(唯一标识)
     * @param coordType  xy 轴坐标模式 坐标类型(0:x 经度 y 纬度；1:x 米 y 米，相对世界 00 点) 目前该属性仅支持属性值为 0
     * @param coordTypeZ  z 轴坐标模式 坐标高度类型，单位：米(0:相对 3D 世界 0 海拔；1:相对 3D 世界地表；2:相对 3D 模型表面) 目前该属性仅支持属性值为 0
     * @param alpha   透明度
     * @param scale  半径(单位:米)
     * @param type  样式类型（具体类型参考下图 type 枚举值）
     * @param titleText  文本标签内容，billboard
     * @param titleColor  特效标签文本颜色，可选值，默认白色
     * @param titleBackgroundColor  特效标签文本背景颜色，可选值，默认灰色
     * @param coord    坐标点 lng,lat
     * @param coordZ    高度（米）
     * @param tag    用户自定标签，用户保存用户的扩展数据
     * @param visible  添加当前图层时默认显示还是隐藏
     *
     */
    add3DMarker({
                    id,
                    coordType,
                    coordTypeZ,
                    alpha,
                    scale,
                    type,
                    titleText,
                    titleColor,
                    titleBackgroundColor,
                    coord,
                    coordZ,
                    tag,
                    visible
                }) {
        return new Promise((resolve) => {
            this.appInstance.uniCall('add3DMarker', {
                id,
                coordType,
                coordTypeZ,
                alpha,
                scale,
                type,
                titleText,
                titleColor,
                titleBackgroundColor,
                coord,
                coordZ,
                tag,
                visible
            }, (result) => {
                resolve(result);
            });
        });
    }

    /**
     * 切换季节
     * @param season 季节
     * @returns {Promise<unknown>}
     */
    switchSeason(season) {
        return new Promise(resolve => {
            this.appInstance.uniCall('playAnimation', {
                animationName: 'SwitchSeason', //切换季节
                state: season
            }, res => {
                console.log(res);
                resolve(res);
            })
        });
    }


    updateLandmarkLayerCoord(data) {
        return new Promise(resolve => {
            this.appInstance.uniCall('updateLandmarkLayerCoord', data, (result) => {
                resolve(result);
            });
        });
    }

    /**
     * 添加关系图
     * @param id    图层对象 id，新建时调用者自己传入的唯一标识，用于各种操作的对象识别
     * @param name  图层名称，支持为图层自定义名称
     * @param coordType  XY 轴坐标类别，0：X 经度，Y 纬度；1：X 米，Y 米，相对世界 00 点 目前该属性仅支持属性值为 0
     * @param coordTypeZ  Z 轴坐标类别，0：相对 3D 世界 0 海拔；1：相对 3D 世界地表；2：相对 3D 模型表面（单位：米） 目前该属性仅支持属性值为 0
     * @param isShowBubble  是否显示气泡，默认显示，true / false
     * @param bubbleRadiusMax  气泡最大半径
     * @param bubbleRadiusMin  气泡最小半径
     * @param bubbleSpeed   气泡扩散速度属性
     * @param lineWidthMax  连线最大宽度(单位:米)
     * @param lineWidthMin   连线最小宽度(单位:米)
     * @param lineSpeed    连线内部飞行速度
     * @param curvature     连线曲度调节(取值范围 -1 ~ 1, 0为直线, 此值越接近0曲线越平, 反之曲线越陡峭)；在地球仪模式下，0：贴近地表，1：曲线最高点高出地球一个地球半径
     * @param valueMax    最大值上限
     * @param valueMin    最小值上限
     * @param visible     添加当前图层时默认显示还是隐藏
     * @param legends     定义图层包含图例，支持为不同图例定义各自样式
     * @param data        定义图层可视化数据
     */
    addODLineLayer({
                       id,
                       name,
                       coordType,
                       coordTypeZ,
                       isShowBubble,
                       bubbleRadiusMax,
                       bubbleRadiusMin,
                       bubbleSpeed,
                       lineWidthMax,
                       lineWidthMin,
                       lineSpeed,
                       curvature,
                       valueMax,
                       valueMin,
                       visible,
                       legends,
                       data
                   }) {
        console.log(data)
        return new Promise(resolve => {
            this.appInstance.uniCall('addODLineLayer', {
                id,
                name,
                coordType,
                coordTypeZ,
                isShowBubble,
                bubbleRadiusMax,
                bubbleRadiusMin,
                bubbleSpeed,
                lineWidthMax,
                lineWidthMin,
                lineSpeed,
                curvature,
                valueMax,
                valueMin,
                visible,
                legends,
                data
            }, (result) => {
                console.log(result)
                resolve(result);
            });
        });
    }

    /**
     * 添加气泡图
     * @param id     图层对象 id，新建时调用者自己传入的唯一标识，用于各种操作的对象识别
     * @param name    图层名称，支持为图层自定义名称
     * @param coordType  XY 轴坐标类别，0：X 经度，Y 纬度；1：X 米，Y 米，相对世界 00 点
     * @param coordTypeZ  Z 轴坐标类别，0：相对 3D 世界 0 海拔；1：相对 3D 世界地表；2：相对 3D 模型表面（单位：米）
     * @param fillArea   区域填充样式类别
     * @param speed     气泡扩散速度（单位：米/秒）
     * @param radiusMax  气泡最大半径（单位：米）
     * @param radiusMin   气泡最小半径(单位:米)色
     * @param valueMax   最大值上限
     * @param valueMin   最小值下限
     * @param visible   添加当前图层时默认显示还是隐藏
     * @param legends   定义图层包含图例，支持为不同图例定义各自样式
     * @param data    定义图层可视化数据
     */
    addBubbleLayer({
                       id,
                       name,
                       coordType,
                       coordTypeZ,
                       fillArea,
                       speed,
                       radiusMax,
                       radiusMin,
                       valueMax,
                       valueMin,
                       visible,
                       legends,
                       data
                   }) {
        return new Promise(resolve => {
            this.appInstance.uniCall('addBubbleLayer', {
                id,
                name,
                coordType,
                coordTypeZ,
                fillArea,
                speed,
                radiusMax,
                radiusMin,
                valueMax,
                valueMin,
                visible,
                legends,
                data
            }, (result) => {
                resolve(result);
            });
        });
    }

    addPath(data) {
        return new Promise(resolve => {
            this.appInstance.uniCall('addPath', data, (result) => {
                console.log('addPath', result)
                resolve(result);
            });
        });
    }

    /**
     * 添加热力图
     * @param id   图层对象 id，新建时调用者自己传入的唯一标识，用于各种操作的对象识别
     * @param name  图层名称，支持为图层自定义名称
     * @param coordType  XY 轴坐标类别，0：X 经度，Y 纬度；1：X 米，Y 米，相对世界 00 点 目前该属性仅支持属性值为 0
     * @param coordTypeZ Z 轴坐标类别，0：相对 3D 世界 0 海拔；1：相对 3D 世界地表；2：相对 3D 模型表面（单位：米） 目前该属性仅支持属性值为 0
     * @param coordZ 高度(单位:米)
     * @param type  外观类型，default：标准热图；dot：点状热图
     * @param alpha 透明度，0：完全透明；1：完全不透明
     * @param colorMax 最大值上限颜色（HEX 颜色值）
     * @param colorMin  最小值下限颜色（HEX 颜色值）
     * @param valueMax  最大值上限
     * @param valueMin  最大值上限
     * @param radius  每一个点得半径单位（米）
     * @param visible  添加当前图层时默认显示还是隐藏
     * @param pointDataId  对应本服务器上点数据对象，如果找到 pointDataId，则下方的 data 不起作用（注意：此属性端渲染未生效）
     * @param data   数据点位基本信息集合
     */
    addHeatMapLayer({
                        id,
                        name,
                        coordType,
                        coordTypeZ,
                        coordZ,
                        type,
                        alpha,
                        colorMax,
                        colorMin,
                        valueMax,
                        valueMin,
                        radius,
                        visible,
                        pointDataId,
                        data
                    }) {
        return new Promise(resolve => {
            this.appInstance.uniCall('addHeatMapLayer', {
                id,
                name,
                coordType,
                coordTypeZ,
                coordZ,
                type,
                alpha,
                colorMax,
                colorMin,
                valueMax,
                valueMin,
                radius,
                visible,
                pointDataId,
                data
            }, (result) => {
                resolve(result);
            });
        });
    }

    /**
     * 添加区域
     * @param id  图层对象 id，新建时调用者自己传入的唯一标识，用于各种操作的对象识别
     * @param name 图层名称，支持为图层自定义名称
     * @param coordType  XY 轴坐标类别，0：X 经度，Y 纬度；1：X 米，Y 米，相对世界 00 点 目前该属性仅支持属性值为 0
     * @param coordTypeZ Z 轴坐标类别，0：相对 3D 世界 0 海拔；1：相对 3D 世界地表；2：相对 3D 模型表面（单位：米） 目前该属性仅支持属性值为 0
     * @param coordZ  高度(单位:米)
     * @param type  样式类型（具体类型参考下图 type 枚举值，端渲染暂不支持 Gradient01）
     * @param color  轮廓颜色(HEX 颜色值)
     * @param areaHeight  围栏高度(单位:米)
     * @param fillArea  底部区域填充类型（具体类型参考下图 fillArea 枚举值）
     * @param fillPosition  区域填充的位置，top/bottom，默认 top
     * @param tag  用户自定标签，用户保存用户的扩展数据
     * @param points  定义区域边界
     *
     */
    addArea({id, name, coordType, coordTypeZ, coordZ, type, color, areaHeight, fillArea, fillPosition, tag, points}) {
        return new Promise(resolve => {
            this.appInstance.uniCall('addArea', {
                id,
                name,
                coordType,
                coordTypeZ,
                coordZ,
                type,
                color,
                areaHeight,
                fillArea,
                fillPosition,
                tag,
                points
            }, (result) => {
                console.log(result);
                resolve(result);
            });
        });
    }

    //添加3维柱状图层
    add3DColumnLayer(data) {
        return new Promise(resolve => {
            this.appInstance.uniCall('add3DColumnLayer', data, (result) => {
                console.log(result);
                resolve(result);
            });
        });
    }

    /**
     * 添加3Dtile倾斜摄影
     * @param data 参数
     * @param data.id 图层对象 id，新建时调用者自己传入的唯一标识，用于各种操作的对象识别
     * @param data.name 图层名称，支持为图层自定义名称
     * @param data.url 3dTile 入口描述文件地址
     * @param data.type 3dTile 类别，b3dm； i3dm；s3dm，目前仅支持 b3dm
     * @param data.token 预留字段
     * @param data.LOD 倾斜摄影数据的细节加载系数。表示在当前视野距离下，加载倾斜摄影数据的级别系数。流渲染默认值是16，如果想更加清晰，可以设置8或者4，一定是2的倍数。端渲染默认是6，如果想更加清晰，可以设置5或者4，最小值为1。加载越清晰的数据会越耗费渲染侧的性能
     * @param data.centerTranslate 倾斜摄影数据中心点在 x, y, z 方向上的二次位移。数据默认值加偏移量是新位置，支持负数。（单位：米）
     * @param data.centerRotation 倾斜摄影数据中心点在 x, y, z 方向上的二次旋转。（单位：度）值域 -360~360
     * @param data.visible 显隐控制，true：显示；false：隐藏
     * @param data.alpha 显隐控制，true：显示；false：隐藏
     * @return {Promise<unknown>}
     */
    add3DTile({id, name, url, type, token, LOD, centerTranslate, centerRotation, visible, alpha}) {
        return new Promise(resolve => {
            this.appInstance.uniCall('add3DTile', {
                id,
                name,
                url,
                type,
                token,
                LOD,
                centerTranslate,
                centerRotation,
                visible,
                alpha
            }, (result) => {
                console.log(result);
                resolve(result);
            });
        })
    }

    //添加模型
    addModel(data) {
        return new Promise(resolve => {
            this.appInstance.uniCall('addModel', data, (result) => {
                console.log(result);
                resolve(result);
            });
        });
    }

//持续路径移动模型
    pathingModel(data) {
        return new Promise(resolve => {
            this.appInstance.uniCall('pathingModel', data, (result) => {
                console.log(result)
                resolve(result);
            });
        });
    }

//模型跟随镜头移动
    followingCamera(data) {
        return new Promise(resolve => {
            this.appInstance.uniCall('followingCamera', data, (result) => {
                console.log(result)
                resolve(result);
            });
        });
    }

    setCameraFollowingState(state) {
        return new Promise(resolve => {
            this.appInstance.uniCall('setCameraFollowingState', {state}, (result) => {
                console.log(result)
                resolve(result);
            });
        });
    }


    setModelPathingState(id, state) {
        return new Promise(resolve => {
            this.appInstance.uniCall('setModelPathingState', {id, state}, (result) => {
                console.log(result)
                resolve(result);
            });
        });
    }

    removeModel(id) {
        return new Promise(resolve => {
            this.appInstance.uniCall('removeModel', {id}, (result) => {
                console.log(result)
                resolve(result);
            });
        });
    }


    /**
     * 鼠标悬停楼层事件
     * @param callback 回调（事件信息）
     */
    onFloorHover(callback) {
        this.setupEventLister('onFloorHover', callback).then();
    }

    /**
     * 3d特效点击事件
     * @param callback 回调（事件信息）
     */
    on3DMarkerClick(callback) {
        this.setupEventLister('on3DMarkerClick', callback).then();
    }

    /**
     * 3d特效鼠标移出事件
     * @param callback 回调（事件信息）
     */
    on3DMarkerUnhover(callback) {
        this.setupEventLister('on3DMarkerUnhover', callback).then();
    }

    /**
     * 鼠标气泡图层点击事件
     * @param callback 回调（事件信息）
     */
    onBubbleLayerClick(callback) {
        this.setupEventLister('onBubbleLayerClick', callback).then();
    }

    /**
     * 鼠标离开楼层事件
     * @param callback 回调（事件信息）
     */
    onFloorUnhover(callback) {
        this.setupEventLister('onFloorUnhover', callback).then();
    }

    /**
     * 鼠标点击楼层事件
     * @param callback 回调（事件信息）
     */
    onFloorClick(callback) {
        this.setupEventLister('onFloorClick', callback).then();
    }

    /**
     * 开启相机位置录制
     * @param callback 回调（事件信息）
     */
    onCameraMove(callback) {
        this.setupEventLister('onCameraMove', callback).then();
    }


    /**
     * 开启图层点击事件监听
     * @param callback 回调（事件信息）
     */
    onLandmarkLayerClick(callback) {
        this.setupEventLister('onLandmarkLayerClick', callback).then();
    }

    /**
     * 轨迹图点击事件监听
     * @param callback 回调（事件信息）
     */
    onTrailLayerClick(callback) {
        this.setupEventLister('onTrailLayerClick', callback).then();
    }

    /**
     * 场景点击事件
     * @param callback 回调（事件信息）
     */
    onSceneClick(callback) {
        this.setupEventLister('onSceneClick', callback).then();
    }

    /**
     * 开启场景切换事件监听
     * @param callback 回调（事件信息）
     */
    onSceneChanged(callback) {
        this.setupEventLister('onSceneChanged', callback).then();
    }


    /**
     * 启动模型点击事件监听
     * @param callback 回调（事件信息）
     */
    onModelClick(callback) {
        this.setupEventLister('onModelClick', callback).then();
    }

    /**
     * 鼠标移出模型事件
     * @param callback 回调（事件信息）
     */
    onModelUnhover(callback) {
        this.setupEventLister('onModelUnhover', callback).then();
    }

    /**
     * 鼠标移入模型事件
     * @param callback 回调（事件信息）
     */
    onModelHover(callback) {
        this.setupEventLister('onModelHover', callback).then();
    }

    /**
     * 鼠标移入建筑事件
     * @param callback 回调（事件信息）
     */
    onBuildingHover(callback) {
        this.setupEventLister('onBuildingHover', callback).then();
    }

    /**
     * 鼠标移出建筑事件
     * @param callback 回调（事件信息）
     */
    onBuildingUnhover(callback) {
        this.setupEventLister('onBuildingUnhover', callback).then();
    }

    /**
     * 鼠标点击建筑事件
     * @param callback 回调（事件信息）
     */
    onBuildingClick(callback) {
        this.setupEventLister('onBuildingClick', callback).then();
    }

    /**
     * 启动事件监听
     * @param eventName 图观api事件名称
     * @param callback 事件（事件信息）
     */
    setupEventLister(eventName, callback) {
        return new Promise((resolve, reject) => {
            this.appInstance.uniCall(
                'addEventListener',
                {
                    eventName: eventName,
                    callback: callback
                },
                (result) => {

                    if (result.result != 1) {
                        console.error(result, 'setup-event-error-' + eventName);
                        reject(result);
                    } else {
                        resolve(true);
                    }
                }
            );
        });

    }

    /**
     * 销毁3d对象
     */
    destroy() {
        if (this.appInstance) {
            this.appInstance.uniCall('destroy', {}, (result) => {
                console.log(result, 'destroy');
            });
        }
    }

    removeEventListener(eventName, callback) {
        return new Promise(resolve => {
            this.appInstance.unicall('removeEventListener', {eventName, callback}, (result) => {
                console.log('removeEventListener', result);
                resolve(result)
            })
        })
    }

    //
    // /**
    //  * 销毁所有事件监听
    //  */
    // destroyAllEvents(){
    //     for (let key in ThreeD.EVENT_TYPE) {
    //         document.removeEventListener(ThreeD.EVENT_TYPE[key],()=>{
    //             console.log('移除所有事件成功')
    //         });
    //     }
    // }
    /**
     *
     * @param {*} data state 状态
     * @returns
     */
    setCameraRestrictionState(data) {
        return new Promise((resovle, reject) => {
            this.appInstance.uniCall('setCameraRestrictionState', data, (result) => {
                console.log(result);
            });
        })
    }

    roamingCamera(jsonData) {
        return new Promise((resolve) => {
            this.appInstance.uniCall('roamingCamera', jsonData, (result) => {
                console.log(result);
            })
        })
    }

    /**
     * 设置漫游镜头状态
     * @param {*} state 设置状态
     * @returns
     */
    setCameraRoamingState() {
        return new Promise((resolve) => {
            this.appInstance.uniCall('setCameraRoamingState', {state: 'pause'}, (result) => {
                console.log(result);
                resolve(result);
            })
        })
    }

    /**
     *
     * @param {*} param0
     * @returns
     */
    focusById({id, overlayType, distance}) {
        return new Promise((resolve) => {
            this.appInstance.uniCall('focusById', {id, overlayType, distance}, (result) => {
                console.log(result);
                resolve(result)
            })
        })
    }

    /**
     * @param pathId    路径 ID (唯一标识)
     * @param loopMode  循环模式：none，不循环；round，往返；repeat，从头循环
     * @param reverse   是否反向移动
     * @param distance  镜头距离覆盖物距离(单位:米)
     * @param pitch     镜头俯仰角(0~89)
     * @param speed     镜头速度 (单位:米/秒)
     */
    pathingCamera({pathId, loopMode, reverse, distance, pitch, speed}) {
        return new Promise((resolve) => {
            this.appInstance.uniCall('pathingCamera', {pathId, loopMode, reverse, distance, pitch, speed}, (result) => {
                console.log('pathingCamera', result)
                resolve(result)
            })
        })
    }

    setCameraPathingState() {
        return new Promise((resolve) => {
            this.appInstance.uniCall('setCameraPathingState', {state: state}, (result) => {
                console.log(result);
                resolve(result)
            })
        })
    }

    /**
     * @param idLayer 需要移动的 图层id
     * @param offset  移动图层偏移几个，是相对图层的数量，如果是 0，表示前移到最上面，值域只能是大于 0
     *
     */
    moveOverlayForward({idLayer, offset}) {
        return new Promise((resolve) => {
            this.appInstance.uniCall('moveOverlayForward', {idLayer, offset}, (result) => {
                console.log(result);
                resolve(result)
            })
        })
    }

    /**
     *
     * @param overlayType  图层对象类别
     * @param idLayer      图层id
     * @param type          框选模式，rect：屏幕矩形；circle：地表圆形；polygon：地表多边形
     */
    selectOverlay({overlayType, idLayer, type}) {
        return new Promise((resolve) => {
            this.appInstance.uniCall('selectOverlay', {overlayType, idLayer, type}, (result) => {
                console.log(result);
                resolve(result)
            })
        })
    }

    /**
     * 添加地标点
     * @param id  图层 ID
     * @param coordType  XY 轴坐标类别，0：X 经度，Y 纬度；1：X 米，Y 米，相对世界 00 点
     * @param coordTypeZ Z 轴坐标类别，0：相对 3D 世界 0 海拔；1：相对 3D 世界地表；2：相对 3D 模型表面（单位：米） 目前该属性仅支持属性值为 0
     * @param iconName  内置图标名称，或根据项目，在场景中预置好的图标对象的名称，包含文字样式定义，具体图标名称参考下表和下图
     * @param autoScale   如果开启后，图标会按照摄像机远近自动缩放大小
     * @param label    图标标签文本
     * @param iconScale  默认值是1表示原大，0 - 1：表示缩小倍率，大于1：表示放大倍率
     * @param labelScale  默认值是1表示原大，0 - 1：表示缩小倍率，大于1：表示放大倍率
     * @param tag   用户自定标签，用户保存用户的扩展数据
     * @param coordType  XY 轴坐标，X：经度；Y：纬度
     * @param coord  Z 轴高度（单位：米）
     * @param
     */
    addLandmark({id, coordType, coordTypeZ, iconName, autoScale, label, iconScale, labelScale, tag, coord, coordZ}) {
        return new Promise((resolve) => {
            this.appInstance.uniCall('addLandmark', {
                id,
                coordType,
                coordTypeZ,
                iconName,
                autoScale,
                label,
                iconScale,
                labelScale,
                tag,
                coord,
                coordZ
            }, (result) => {
                console.log(result);
                resolve(result)
            })
        })
    }

    /**
     * 更新地标点 位置
     * @param id  图层对象 id，新建时调用者自己传入的唯一标识，用于各种操作的对象识别
     * @param coordType  XY 轴坐标类别，0：X 经度，Y 纬度；1：X 米，Y 米，相对世界 00 点
     * @param coordTypeZ  Z 轴坐标类别，0：相对 3D 世界 0 海拔；1：相对 3D 世界地表；2：相对 3D 模型表面（单位：米） 目前该属性仅支持属性值为 0
     * @param tag   用户自定标签，用户保存用户的扩展数据
     * @param coord  XY 轴坐标，X：经度；Y：纬度
     * @param coordZ  Z 轴高度（单位：米）
     *
     *
     */
    updateLandmarkCoord({id, coordType, coordTypeZ, tag, coord, coordZ}) {
        return new Promise((resolve) => {
            this.appInstance.uniCall('updateLandmarkCoord', {
                id,
                coordType,
                coordTypeZ,
                tag,
                coord,
                coordZ
            }, (result) => {
                console.log(result);
                resolve(result)
            })
        })
    }

    /**
     *
     * @param  id   图层对象 id，新建时调用者自己传入的唯一标识，用于各种操作的对象识别
     */
    getLandmarkScreenPosition({id}) {
        return new Promise((resolve) => {
            this.appInstance.uniCall('getLandmarkScreenPosition', {id}, (result) => {
                console.log(result);
                resolve(result)
            })
        })
    }

    /**
     * 更新路径
     * @param id
     * @param name
     * @param coordType
     * @param coordTypeZ
     * @param isAppend
     * @param tag
     * @param points
     */
    updatePathCoord({id, name, coordType, coordTypeZ, isAppend, tag, points}) {
        return new Promise((resolve) => {
            this.appInstance.uniCall('updatePathCoord', {
                id,
                name,
                coordType,
                coordTypeZ,
                isAppend,
                tag,
                points
            }, (result) => {
                console.log(result);
                resolve(result)
            })
        })
    }

    /**
     *
     * @param id  图层对象 id，新建时调用者自己传入的唯一标识，用于各种操作的对象识别
     * @param name  图层名称，支持为图层自定义名称
     * @param coordType  XY 轴坐标类别，0：X 经度，Y 纬度；1：X 米，Y 米，相对世界 00 点
     * @param coordTypeZ  Z 轴坐标类别，0：相对 3D 世界 0 海拔；1：相对 3D 世界地表；2：相对 3D 模型表面（单位：米）
     * @param visible  添加当前图层时默认显示还是隐藏
     * @param legends  定义图层包含图例，支持为不同图例定义各自样式
     * @param data  定义图层可视化数据
     */
    addTrackLayer({id, name, coordType, coordTypeZ, visible, legends, data}) {
        return new Promise((resolve) => {
            this.appInstance.uniCall('addTrackLayer', {
                id,
                name,
                coordType,
                coordTypeZ,
                visible,
                legends,
                data
            }, (result) => {
                console.log(result);
                resolve(result)
            })
        })
    }

    /**
     * 添加模型地表图
     * @param id
     * @param coordType
     * @param coordTypeZ
     * @param snapSurface
     * @param alpha
     * @param modelMaxDistance
     * @param iconMaxDistance
     * @param modelLandmarkDataId
     * @param visible
     * @param legends
     * @param data
     */
    addModelLandmarkLayer({
                              id,
                              coordType,
                              coordTypeZ,
                              snapSurface,
                              alpha,
                              modelMaxDistance,
                              iconMaxDistance,
                              modelLandmarkDataId,
                              visible,
                              legends,
                              data
                          }) {
        return new Promise((resolve) => {
            this.appInstance.uniCall('addModelLandmarkLayer', {
                id,
                coordType,
                coordTypeZ,
                snapSurface,
                alpha,
                modelMaxDistance,
                iconMaxDistance,
                modelLandmarkDataId,
                visible,
                legends,
                data
            }, (result) => {
                console.log(result);
                resolve(result)
            })
        })
    }

    /**
     * @param id
     * @param name
     * @param coordType
     * @param coordTypeZ
     * @param alpha
     * @param width
     * @param colorMax
     * @param colorMin
     * @param valueMax
     * @param valueMin
     * @param lineDataId
     * @param visible
     *
     */
    addRoadSgHeatLayerCoord({
                                id,
                                name,
                                coordType,
                                coordTypeZ,
                                alpha,
                                width,
                                colorMax,
                                colorMin,
                                valueMax,
                                valueMin,
                                lineDataId,
                                visible
                            }) {
        return new Promise((resolve) => {
            this.appInstance.uniCall('addRoadSgHeatLayerCoord', {
                id,
                name,
                coordType,
                coordTypeZ,
                alpha,
                width,
                colorMax,
                colorMin,
                valueMax,
                valueMin,
                lineDataId,
                visible,
            }, (result) => {
                console.log(result);
                resolve(result)
            })
        })
    }

    /**
     * 添加特效地表图
     * @param id    模型对象 id，新建时调用者自己传入的唯一标识，用于各种操作的对象识别
     * @param coordType   XY 轴坐标类别，0：X 经度，Y 纬度；1：X 米，Y米，相对世界00点
     * @param coordTypeZ  Z 轴坐标类别，0：相对 3D 世界 0 海拔；1：相对 3D 世界地表；2：相对 3D 模型表面（单位：米）
     * @param snapSurface 启用自动贴地
     * @param alpha  透明度，0：完全透明；1：完全不透明
     * @param markerMaxDistance  特效最大可见距离（单位：米），不可见后显示icon
     * @param iconMaxDistance  icon最大可见距离（单位：米），不可见后隐藏
     * @param markerLandmarkDataId 对应本服务器上3DMarkerLandmark数据Id，如果找到id，则下方的data不起作用
     * @param visible  添加当前图层时默认显示还是隐藏
     * @param legends  样式
     * @param data     数据
     * @param
     * @param
     */
    add3DMarkerLandmarkLayer({
                                 id,
                                 coordType,
                                 coordTypeZ,
                                 snapSurface,
                                 alpha,
                                 markerMaxDistance,
                                 iconMaxDistance,
                                 markerLandmarkDataId,
                                 visible,
                                 legends,
                                 data
                             }) {
        return new Promise((resolve) => {
            this.appInstance.uniCall('add3DMarkerLandmarkLayer', {
                id,
                coordType,
                coordTypeZ,
                snapSurface,
                alpha,
                markerMaxDistance,
                iconMaxDistance,
                markerLandmarkDataId,
                visible,
                legends,
                data
            }, (result) => {
                resolve(result)
            })
        })
    }

    /**
     * 设置指南针
     * @param dockHorizontal
     * @param dockVertical
     * @param offset
     * @param size
     * @param visible
     */
    setCompass({dockHorizontal, dockVertical, offset, size, visible}) {
        return new Promise((resolve) => {
            this.appInstance.uniCall('setCompass', {dockHorizontal, dockVertical, offset, size, visible}, (result) => {
                console.log(result);
                resolve(result)
            })
        })
    }

    /**
     *
     * @param watermarkId  水印 id
     * @param url   水印图片
     * @param dockHorizontal  水平对齐方式 left, center, right
     * @param dockVertical   垂直对齐方式 top, middle, bottom
     * @param offset    以水印原本位置的左上角为基准，向右下偏移的像素量，本例中是向右偏移 250 像素，向下偏移 100 像素
     * @param scale     缩放系数 0.01 ~ 10 倍
     * @param alpha     透明度 0 ~ 1
     */
    addWatermark({watermarkId, url, dockHorizontal, dockVertical, offset, scale, alpha}) {
        return new Promise((resolve) => {
            this.appInstance.uniCall('addWatermark', {
                watermarkId,
                url,
                dockHorizontal,
                dockVertical,
                offset,
                scale,
                alpha
            }, (result) => {
                console.log(result);
                resolve(result)
            })
        })
    }
}

// /**
//  * 事件执行
//  * @constructor
//  */
// function ThreeDEventHandler() {
// }
//
// ThreeDEventHandler.sendLandmarkLayerClickEvent = (e) => {
//     document.dispatchEvent(new CustomEvent(ThreeD.EVENT_TYPE.landmarkLayerClick, {detail: e}));
// }
// ThreeDEventHandler.sendSceneChangedEvent = (e) => {
//     document.dispatchEvent(new CustomEvent(ThreeD.EVENT_TYPE.sceneChanged, {detail: e}));
// }
// ThreeDEventHandler.send3dEvent = (e,eventName) => {
//     document.dispatchEvent(new CustomEvent(eventName, {detail: e}));
// }

