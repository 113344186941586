export default{
    name: "路径",
    coordType: 0,
    coordTypeZ: 0,
    type: "Arrow03",
    texture: "",
    textureSpeed: 1,
    color: "#f58f5c",
    colorPass: "#f58f5c",
    width: 10,
    tag: "custominfo", // 用户自定标签，用户保存用户的扩展数据
    autoScale: true,
    visible: false,
    lineDataId: "",
}
